import { ApiStudentRequest, request } from '../utils/request'
import { useQuery } from '@tanstack/react-query'

export type RawSpaConfigResponse = {
  auth: {
    issuer: string
    clientId: string
    redirectUri: string
  }
}

export function useGetSpaConfig() {
  const { isLoading, data } = useQuery({
    queryKey: ['spaConfig'],
    queryFn: async () => {
      try {
        const response = await request<RawSpaConfigResponse>(
          new ApiStudentRequest(`/spa-config`, { method: 'GET' }),
          {
            isExpectedResponse: (res): res is RawSpaConfigResponse => res,
          },
        )
        return response
      } catch (error) {
        console.warn('No config found for this tenant')
        return null
      }
    },
    enabled: true,
    refetchOnWindowFocus: false,
  })

  return {
    isLoading: isLoading,
    config: data?.auth || null,
  }
}
